import "./Portfolio.css";
import gmail from './img/Group(1).svg'

function Portfolio() {
  return (
    <div className="home">
      <nav className="portfolio-header">
        <div className="Name">Renan</div>
        <div className="links">
          <a href="https://github.com/renanbmello">
          <img className="git-img" src={require('./img/git.png')} alt="github" />
          </a>
          <a href="mailto:renanbmello@gmail.com"><img
            className="git-img"
            src={gmail}
            alt="gmail"
          /></a>
        </div>
      </nav>
      <div className="Portfolio">
        <div className="about">
          <p className="welcome">
            <span className="title">Welcome.</span>
          </p>
          <div className="presentation">
            <div className="line-frame">
              <div className="renan-wrapper">
                <img src={require('./img/Line.png')} alt="line" />
                <p className="renan">
                  My name is Renan Mello, and I am a full-stack developer based
                  in Belém, Pará, Brazil. I have experience working on systems
                  for the financial market.
                  <br />
                  <br />I am passionate about web3 and the financial market, as
                  well as delivering a good and intuitive user experience (UX).
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="all-exp">
          <div className="exp-instance">
            <div className="date-exp">
              January<br /> -<br /> August
              <br /> 2021
            </div>
            <div className="exp-description">
              <p id="description-text">
                <b>INOA - Fullstack</b>
                <br /> <br /> Rio de Janeiro B2B company with about 50 software
                developers that work with the biggest financial market brokers
                in Brazil. The company's main goal is to automate, manage and
                integrate processes, in order to create a more efficient
                financial market.
                <br />
                <br /> Implemented automatic execution of market options on
                their maturity date, directing the funds to respective wallets,
                and developed a dedicated client page. This involves retrieving
                public data from the B3 (Brazilian Stock Exchange) and aligning
                it with the funds' wallets.
                <br /> <br />
                Created scripts for resetting and applying maintenance changes
                on the client server.
              </p>
              <div className="exp-card-block">
              <div className="exp-card">
                  <div className="card-text">Django</div>
                </div><div className="exp-card">
                  <div className="card-text">JavaScript</div>
                </div>
              </div>
            </div>
            <img src={require('./img/Line2.png')} alt="line2" />
          </div>
          <div className="exp-instance">
            <div className="date-exp">
              July 2020
              <br /> -
              <br /> January 2021
            </div>
            <div className="exp-description">
              <p id="description-text">
                <b>AGE PA - IT Manager</b> <br />
                <br />
                State-owned audit firm.
                <br /> <br /> Managed the infra net server as well as the
                firewall.
              </p>
              <div className="exp-card-block">
              <div className="exp-card">
                <div className="card-text">Windows Server</div>
              </div>
              </div>
            </div>
            <img src={require('./img/Line2.png')} alt="line2" />
          </div>
          <div className="exp-instance">
            <div className="date-exp">
              November 2018 <br />- <br />
              December 2019
            </div>
            <div className="exp-description">
              <p id="description-text">
                <b>PRODEPA - Inter </b> <br /> <br />
                Set up and install network switches for the Pará state.
                <br /> <br />
                Monitor the network using Zabbix.
              </p>
              <div className="exp-card-block">
              <div className="exp-card">
                <div className="card-text">Switch Programming</div>
              </div>
              </div>
            </div>
            <img src={require('./img/Line2.png')} alt="line2" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Portfolio;
